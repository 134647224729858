import React from 'react';
import { sectionStyles, wrapperStyles, Logo } from './styles/index.styled';

import Section from '../../molecules/Section/Section';

const LoadingPage = () => (
  <Section style={sectionStyles} innerStyle={wrapperStyles}>
    <Logo>
      <img
        src="https://www.datocms-assets.com/38431/1615578028-shopmonkey-icon.svg"
        alt="Shopmonkey"
      />
    </Logo>
  </Section>
);

export default LoadingPage;
